import "react-step-progress-bar/styles.css";
import { ALL_STEPS_LIST } from "../../containers/Form/constants";
import { useTranslation } from 'react-i18next';

import './style.css';

const Progress = ({ currentStep }) => {

    const { t, i18n } = useTranslation();

    const getStepLabel = (step) => {
        // Define your step labels here
        const stepLabels = {
            STEP_1: `${t('description.part6')}`,
            STEP_2: `${t('description.part7')}`,
            STEP_3: `${t('description.part8')}`,
            STEP_4: `${t('description.part9')}`,
        };
    
        return stepLabels[step] || '';
    };

    return (
        <div className={`flex center ${ (i18n.language === 'de' || i18n.language === 'fa') ? 'flex-row-reverse' : ''}`}>
        {ALL_STEPS_LIST.map((step, index) => (
            <div
            key={index}
            className={`steps ${step.toLowerCase()} ${currentStep >= index + 1 ? 'darkStep' : 'lightStep'}`}
        >
            {getStepLabel(step)}
        </div>
        ))}
    </div>
    );
};

export default Progress;
